export default {
  categories: state => state.categories,
  products: state => state.products,
  sliders: state => state.sliders,
  show_account: state => state.show_account,
  show_basket: state => state.show_basket,
  contact_page: state => state.contact_page,
  terms_page: state => state.terms_page,
  privacy_page: state => state.privacy_page,
  about_page: state => state.about_page,
}
