const state = () => ({
  categories: [],
  products: [],
  show_account: false,
  show_basket: false,
  contact_page: [],
  terms_page: [],
  privacy_page: [],
  about_page: [],
})

export default state
