import guest from '@/middleware/guest.js'
import auth from '@/middleware/auth.js'
import check from '@/middleware/check-auth.js'
export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/Login.vue'),
    beforeEnter:guest,
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../pages/Register.vue'),
    beforeEnter:guest,
  },
  {
    path: '/reset-pass',
    name: 'ResetPass',
    component: () => import(/* webpackChunkName: "reset-pass" */ '../pages/ResetPass.vue'),
    beforeEnter:guest,
  },
  {
    path: '/reset-password/:token/:email',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "reset-pass" */ '../pages/Reset.vue'),
    beforeEnter:guest,
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "login" */ '../pages/Account.vue'),
    beforeEnter:auth,
  },
];
