import axios from 'axios';
import { SET_PRODUCTS_MUTATIONS, SET_CATEGORIES_MUTATIONS, SET_PRODUCT, SET_PRODUCT_FAIL } from './types';
import {API_URL} from '@/.env';

export default {
  async getProductBySlug ({ commit }, { slug }) {
    try {
      const { data } = await axios.get(`${API_URL}/api/product/${slug}`)
      commit(SET_PRODUCT, { product: data})
    } catch (e) {
      commit(SET_PRODUCT_FAIL)
    }
  },
  async getProducts(context, { text ='',sortCol='',sortBy='',categories=[] }) {
    const { data } = await axios.get(`${API_URL}/api/products?sortCol=${sortCol}&sortBy=${sortBy}&categories=${categories}`);

    context.commit(SET_PRODUCTS_MUTATIONS, data);

    context.dispatch('log', text);
  },
  async getCategories(context, { text }) {
    const { data } = await axios.get(`${API_URL}/api/categories`);

    context.commit(SET_CATEGORIES_MUTATIONS, data);

    context.dispatch('log', text);
  },
  log(ctx, payload) {
    console.log(payload);
  },
};
