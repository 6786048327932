import { ADD_PRODUCT, CHANGE_QUNTITY, REMOVE_ITEM, EMPTY_CART } from './types'

export const mutations = {
  [ADD_PRODUCT](state, item) {
    const product = state.items.find(product => product.id === item.id)
    if(product) {
      product.quantity++
    } else {
      state.items.push({ ...item, quantity: 1 })
    }
  },
  [CHANGE_QUNTITY](state, payload) {
    const product = state.items.find(product => product.id === payload.id)
    if(product.quantity == 1) {
       return
    }
    product.quantity--
  },
  [REMOVE_ITEM](state, item) {
    state.items = state.items.filter(product => product.id !== item.id)
  },
  [EMPTY_CART](state) {
    state.items = []
  }
}
