import store from '@/store'

export default (to, from, next) => {

  if (store.getters['userName/token']) {
    next({ name: 'Account' })
  } else {
    next()
  }
}
