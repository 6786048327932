import axios from 'axios';
import { SET_CATEGORIES_MUTATIONS, SET_PRODUCTS_MUTATIONS, HIDE_ACCOUNT, HIDE_BASKET, HIDE_BASKET_AND_ACCOUNT, SET_CONTACT_PAGE_MUTATIONS, SET_TERMS_PAGE_MUTATIONS, SET_PRIVACY_PAGE_MUTATIONS, SET_SLIDERS, SET_ABOUT_PAGE_MUTATIONS } from './types';
import {API_URL} from '@/.env';
export default {
  async getCategories(context) {
    const { data } = await axios.get(`${API_URL}/api/featured/categories`);

    context.commit(SET_CATEGORIES_MUTATIONS, data);

  },
  async getSliders(context) {
    const { data } = await axios.get(`${API_URL}/api/sliders`);

    context.commit(SET_SLIDERS, data);

  },
  async getProducts(context) {
    const { data } = await axios.get(`${API_URL}/api/featured/products`);

    context.commit(SET_PRODUCTS_MUTATIONS, data);

  },
  async getContactPage(context) {
    const { data } = await axios.get(`${API_URL}/api/contact-us`);

    context.commit(SET_CONTACT_PAGE_MUTATIONS, data);

  },
  async getTermsPage(context) {
    const { data } = await axios.get(`${API_URL}/api/terms`);

    context.commit(SET_TERMS_PAGE_MUTATIONS, data);

  },
  async getPrivacyPage(context) {
    const { data } = await axios.get(`${API_URL}/api/privacy`);

    context.commit(SET_PRIVACY_PAGE_MUTATIONS, data);

  },
  async getAboutPage(context) {
    const { data } = await axios.get(`${API_URL}/api/about`);

    context.commit(SET_ABOUT_PAGE_MUTATIONS, data);

  },
  hideAccount({ commit, state }, payload) {
    commit(HIDE_ACCOUNT, !state.show_account);
    commit(HIDE_BASKET, false);
  },
  hideBasket({ commit, state }, payload) {
    commit(HIDE_BASKET, !state.show_basket);
    commit(HIDE_ACCOUNT, false);
  },
  hideBasketAndAccount({ commit }) {
    commit(HIDE_BASKET, false);
    commit(HIDE_ACCOUNT, false);
  },
};
