<template>
  <header
    id="header"
    class="w-full z-30 top-0 py-1 border-b shadow-sm"
  >
    <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-6 py-3">
      <label
        for="menu-toggle"
        class="cursor-pointer md:hidden block"
      >
        <svg
          class="fill-current text-gray-900"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </label>
      <input
        id="menu-toggle"
        class="hidden"
        type="checkbox"
      >

      <div
        id="menu"
        class="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1"
      >
        <nav>
          <ul class="md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0">
            <li class="text-center">
              <router-link
                :to="{ name: 'Home' }"
                class="inline-block no-underline hover:text-black  py-2 px-4"
              >
                Kryefaqja
              </router-link>
            </li>
            <li class="text-center">
              <router-link
                :to="{ name: 'Shop' }"
                class="inline-block no-underline hover:text-black  py-2 px-4"
              >
                Dyqani
              </router-link>
            </li>
            <li class="text-center">
              <router-link
                :to="{ name: 'About' }"
                class="inline-block no-underline hover:text-black  py-2 px-4"
              >
                Rreth Nesh
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <div class="order-1 md:order-2">
        <router-link
          :to="{ name: 'Home' }"
          class="flex items-center tracking-wide border-0 no-underline hover:no-underline font-bold text-gray-800 text-xl"
        >
          <logo class="fill-indigo-800"/>
        </router-link>
      </div>

      <div
        id="nav-content"
        class="order-2 md:order-3 flex items-center"
      >
        <a
          class="inline-block no-underline hover:text-black relative"
          href="#"
          @click.prevent="hideAccount"
        >
          <icon
            name="account"
            class="w-5 h-5  fill-current hover:text-black"
          />
        </a>
        <div
          v-show="show_account"
          class="absolute right-0 top-0 mt-20 bg-white rounded-md shadow-lg overflow-hidden z-50"
          style="width:20rem;"
        >
          <div class="py-2">
            <router-link
              :to="{ name: 'Login' }"
              class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
            >
              <icon
                name="login"
                class="h-5 w-5 object-cover mx-1"
              />
              <p class="text-gray-600 text-sm mx-2">
                <span
                  class="font-bold"
                  href="#"
                >Llogaria ime</span>
              </p>
            </router-link>
            <router-link
              v-if="!user"
              :to="{ name: 'Register' }"
              class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
            >
              <icon
                name="register"
                class="h-5 w-5 object-cover mx-1"
              />
              <p class="text-gray-600 text-sm mx-2">
                <span class="font-bold">Regjistrohu</span>
              </p>
            </router-link>
          </div>
        </div>
        <a
          class="pl-3 inline-block no-underline hover:text-black relative"
          href="#"
          @click.prevent="hideBasket"
        >
          <div class="absolute text-xs rounded-full -mt-2 -mr-2 px-1 font-bold top-0 right-0 bg-red-700 text-white">{{ count }}</div>
          <icon
            name="shopping-cart"
            class="w-5 h-5 fill-current hover:text-black"
          />
        </a>
        <div
          v-show="show_basket"
          class="absolute right-0 top-0 mt-20 bg-white rounded-md shadow-lg overflow-hidden z-50"
          style="width:20rem;"
        >
          <div>
            <div
              v-for="item in items"
              :key="item.id"
              class="p-2 flex bg-white hover:bg-gray-100 cursor-pointer border-b border-gray-100"
            >
              <div class="p-2 w-12">
                <img
                  :src="item.photo ? item.photo : require('@/assets/img/thumb.jpg')"
                  :alt="item.name"
                >
              </div>
              <div class="flex-auto text-sm w-32">
                <div class="font-bold">
                  {{ item.name }}
                </div>
                <div class="text-gray-400">
                  Sasia: {{ item.quantity }}
                </div>
              </div>
              <div class="flex flex-col w-18 font-medium items-end">
                <div class="w-4 h-4 mb-6 hover:bg-red-200 rounded-full cursor-pointer text-red-700">
                  <icon
                    name="delete"
                    class="w--100 h--100 feather feather-trash-2"
                    @click="removeItem(item)"
                  />
                </div>
                {{ $filters.currency(item.sale_price*item.quantity) }}
              </div>
            </div>
          </div>
          <router-link
            :to="{ name: 'Cart' }"
            class="block bg-gray-800 text-white text-center font-bold py-2"
          >
            Rezervo Porosinë
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from '@/components/Logo';
import Icon from '@/components/Icon';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'BaseHeader',
  components: {
    Logo,
    Icon,
  },
  data: () => ({
    isBasketDropDown: false,
    isAccountDropDown: false,
    showSideBar: false,
  }),

  computed: {
    ...mapState('cart', ['items']),
    ...mapState('home', ['show_account']),
    ...mapState('home', ['show_basket']),
    ...mapGetters('cart', ['count']),
    ...mapGetters('userName', ['user']),
  },
  created() {
    this.$store.dispatch('userName/fetchUser')
  },
  methods: {
    ...mapActions('cart', ['removeItem']),
    ...mapActions('home', ['hideAccount']),
    ...mapActions('home', ['hideBasket']),
  },
};
</script>

<style></style>
