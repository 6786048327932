export default [
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "category" */ '../pages/Shop.vue'),
  },
  {
    path: '/product/:slug',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../pages/Product.vue'),
  },
];
