import Cookies from 'js-cookie'
import {SAVE_TOKEN, FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILURE, LOGOUT, FETCH_ORDERS, FETCH_ORDERS_FAILURE} from './types'
import {API_URL} from '@/.env';
import axios from 'axios';
export default {
  namespaced: true,
  state: () => ({
    user: null,
    token: Cookies.get('token'),
    orders: [],
  }),
  getters: {
    orders: state => state.orders,
    user: state => state.user,
    token: state => state.token,
    check: state => state.user !== null
  },
  mutations: {
    [SAVE_TOKEN] (state, { token, remember }) {
      state.token = token
      Cookies.set('token', token, { expires: remember ? 365 : 1 })
    },

    [FETCH_USER_SUCCESS] (state, { user }) {
      state.user = user
    },

    [FETCH_USER_FAILURE] (state) {
      state.token = null
      Cookies.remove('token')
    },

    [LOGOUT] (state) {
      state.user = null
      state.token = null
      Cookies.remove('token')
    },
    [FETCH_ORDERS] (state, data) {
      state.orders = data
    },
    [FETCH_ORDERS_FAILURE] (state) {
      state.orders = []
    },
  },
  actions: {
    saveToken ({ commit, dispatch }, payload) {
      commit(SAVE_TOKEN, payload)
    },

    async fetchUser ({ commit, state }) {
      try {
        const { data } = await axios.get(`${API_URL}/api/current-user`,{ headers: {"Authorization" : `Bearer ${state.token}`} })
        commit(FETCH_USER_SUCCESS, { user: data })
      } catch (e) {
        commit(FETCH_USER_FAILURE)
      }
    },
    async fetchOrders ({ commit, state }) {
      try {
        const { data } = await axios.get(`${API_URL}/api/orders`,{ headers: {"Authorization" : `Bearer ${state.token}`} })
        commit(FETCH_ORDERS, data);
      } catch (e) {
        commit(FETCH_ORDERS_FAILURE)
      }
    },

    async logout ({ commit, state }) {
      try {
        await axios.post(`${API_URL}/api/logout`,{},{ headers: {"Authorization" : `Bearer ${state.token}`}})
      } catch (e) { }

      commit(LOGOUT)
    },

  }
}
