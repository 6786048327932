import { createRouter, createWebHashHistory } from 'vue-router';
import { sync } from 'vuex-router-sync'
import store from '@/store'
import userRoutes from '@/modules/user/routes';
import cartRoutes from '@/modules/cart/routes';
import productRoutes from '@/modules/product/routes';
import staticRoutes from '@/modules/static/routes';

const routes = [
  ...cartRoutes,
  ...productRoutes,
  ...userRoutes,
  ...staticRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
});

export default router;
