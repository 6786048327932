import { createApp, inject } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/font.css';
import './scss/app.scss';
import {API_URL} from './.env';
import {currency} from './filters/currency'

const app = createApp(App)

app.config.globalProperties.$filters = {
  currency
}

app.use(router)
  .use(store);

app.mount('#app')

