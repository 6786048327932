import { SET_PRODUCTS_MUTATIONS, SET_CATEGORIES_MUTATIONS, SET_PRODUCT,SET_PRODUCT_FAIL } from './types'

export default {
  [SET_PRODUCTS_MUTATIONS] (state, data) {
    state.products = data
  },
  [SET_CATEGORIES_MUTATIONS] (state, data) {
    state.categories = data
  },
  [SET_PRODUCT] (state, { product }) {
    state.product = product
  },
  [SET_PRODUCT_FAIL] (state) {
    state.product = []
  },
}
