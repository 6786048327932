<template>
    <svg xmlns="http://www.w3.org/2000/svg" height="33.48" viewBox="0 0 200 33.48" class="md:w-full w-32">
        <path class="a" d="M52.28,19c-1.6,1.55-4.06,2.33-7.35,2.33H39.49v11.8H35.8V4.86h9.13q4.78,0,7.27,2.31a7.74,7.74,0,0,1,2.5,6A7.82,7.82,0,0,1,52.28,19Zm-2.81-2a4.91,4.91,0,0,0,1.46-3.81q0-5.23-6-5.23H39.49V18.28h5.44A6.58,6.58,0,0,0,49.47,16.94Z"/>
        <path class="a" d="M65.75,11.55a8.57,8.57,0,0,1,4.39-1.05v3.81h-1Q63,14.31,63,21V33.12H59.28V10.9H63v3.61A7,7,0,0,1,65.75,11.55Z"/>
        <path class="a" d="M74.52,6.56a2.44,2.44,0,0,1-.73-1.78,2.49,2.49,0,0,1,2.52-2.52A2.34,2.34,0,0,1,78,3a2.5,2.5,0,0,1,.71,1.79A2.46,2.46,0,0,1,78,6.56a2.3,2.3,0,0,1-1.72.73A2.42,2.42,0,0,1,74.52,6.56Zm3.57,4.34V33.12H74.4V10.9Z"/>
        <path class="a" d="M115.71,11.57a7.78,7.78,0,0,1,3.21,3.22A10.84,10.84,0,0,1,120.09,20v13.1h-3.65V20.55a7.18,7.18,0,0,0-1.64-5.09,5.78,5.78,0,0,0-4.44-1.76,5.93,5.93,0,0,0-4.58,1.84,7.61,7.61,0,0,0-1.7,5.33V33.12h-3.65V20.55a7.23,7.23,0,0,0-1.64-5.09,5.81,5.81,0,0,0-4.44-1.76,5.92,5.92,0,0,0-4.58,1.84,7.57,7.57,0,0,0-1.71,5.33V33.12H84.37V10.9h3.69v3.2A7.28,7.28,0,0,1,91,11.43a8.93,8.93,0,0,1,4.08-.93A9.57,9.57,0,0,1,100,11.75a7.7,7.7,0,0,1,3.21,3.69,7.3,7.3,0,0,1,3.08-3.65,9.09,9.09,0,0,1,4.78-1.29A9.66,9.66,0,0,1,115.71,11.57Z"/>
        <path class="a" d="M130.17,32.06a10.09,10.09,0,0,1-4-4A12.34,12.34,0,0,1,124.76,22a12,12,0,0,1,1.48-6,10.25,10.25,0,0,1,4-4,12.44,12.44,0,0,1,11.43,0,10.29,10.29,0,0,1,4,4,12,12,0,0,1,1.48,6A11.85,11.85,0,0,1,145.7,28a10.56,10.56,0,0,1-4.12,4,11.76,11.76,0,0,1-5.76,1.42A11.32,11.32,0,0,1,130.17,32.06Zm9.38-2.75a7,7,0,0,0,2.82-2.8A8.92,8.92,0,0,0,143.45,22a9.1,9.1,0,0,0-1.06-4.54,6.94,6.94,0,0,0-2.75-2.78,7.7,7.7,0,0,0-3.69-.91,7.61,7.61,0,0,0-3.71.91,6.71,6.71,0,0,0-2.7,2.78,9.32,9.32,0,0,0-1,4.54,9.66,9.66,0,0,0,1,4.58,6.66,6.66,0,0,0,2.65,2.78,7.46,7.46,0,0,0,3.65.91A7.78,7.78,0,0,0,139.55,29.31Z"/>
        <path class="a" d="M21.93,9.92H0c-.28,11,4.12,15.92,5.68,17.32a1.71,1.71,0,0,0,1.16.44h8.32A1.77,1.77,0,0,0,16.54,27C22.19,19.86,21.93,9.92,21.93,9.92ZM13.86,24.85H8.27A1.74,1.74,0,0,1,7,24.27C5.82,23,3.31,19.46,2.94,13H18.81a19.65,19.65,0,0,1-3.55,11.15A1.71,1.71,0,0,1,13.86,24.85Z"/>
        <path class="a" d="M23.45,13.37h5.46S27.8,21.74,21.1,24.85a4.66,4.66,0,0,1,1.24-4.56c2.08-2.08,2.12-1.52,2.58-3.18s-1.54-1.39-1.54-1.39Z"/>
        <rect class="a" y="30.1" width="21.93" height="3.38" rx="0.55"/>
        <path class="a" d="M12.64,0c.51,1.13,1,2.24,1.51,3.32a3,3,0,0,1-1.1,4.18C12.48,6.24,11.87,5,11.36,3.72A2.85,2.85,0,0,1,12.64,0Z"/>
        <path class="a" d="M8.77,2.61c.35.78.7,1.56,1.06,2.33A2.05,2.05,0,0,1,9,7.83c-.33-.74-.65-1.49-1-2.22A2.06,2.06,0,0,1,8.77,2.61Z"/>
    </svg>
</template>
