import { SET_CATEGORIES_MUTATIONS, SET_PRODUCTS_MUTATIONS, HIDE_ACCOUNT, HIDE_BASKET, SET_ABOUT_PAGE_MUTATIONS, SET_CONTACT_PAGE_MUTATIONS, SET_TERMS_PAGE_MUTATIONS, SET_PRIVACY_PAGE_MUTATIONS, SET_SLIDERS } from './types'

export default {
  [SET_CATEGORIES_MUTATIONS] (state, data) {
    state.categories = data
  },
  [SET_PRODUCTS_MUTATIONS] (state, data) {
    state.products = data
  },
  [HIDE_ACCOUNT] (state, data) {
    state.show_account = data
  },
  [HIDE_BASKET] (state, data) {
    state.show_basket = data
  },
  [SET_CONTACT_PAGE_MUTATIONS] (state, data) {
    state.contact_page = data
  },
  [SET_TERMS_PAGE_MUTATIONS] (state, data) {
    state.terms_page = data
  },
  [SET_PRIVACY_PAGE_MUTATIONS] (state, data) {
    state.privacy_page = data
  },
  [SET_ABOUT_PAGE_MUTATIONS] (state, data) {
    state.about_page = data
  },
  [SET_SLIDERS] (state, data) {
    if (data.sliders !== undefined && data.sliders.sliders !== undefined ){

      state.sliders = data.sliders.sliders.map(val=>{
           return {
             img: data.url+'/'+val.value.photo_path,
             photo_path: val.value.photo_path,
             title: val.value.title,
             btn: val.value.button,
             linku: val.value.linku,
           }
        }
      )
    }else{
      state.sliders = []
    }
  }
}
