import { createStore } from 'vuex'

import cart from '@/modules/cart/store'
import user from '@/modules/user/store'
import products from '@/modules/product/store'
import home from '@/modules/static/store'

const store = createStore({
  modules: {
    cart,
    userName: user,
    products,
    home,
  }
})

export default store
