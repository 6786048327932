
export const getters = {
  total(state) {
    return state.items.reduce((prev, item) => {
        return (item.sale_price * item.quantity) + prev
    }, 0)
  },

  tax: (state, getters) => getters.total/6,

  subtotal: (state, getters) => getters.total - getters.tax,

  count: (state) => state.items.length
}
