<template>
  <div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal">
    <BaseHeader @show-sidebar="handleSidebar" />
    <router-view />
    <BaseFooter :show-side-bar="isShowSideBar" />
  </div>
</template>

<script>
import BaseHeader from './components/BaseHeader';
import BaseFooter from './components/BaseFooter';
export default {

  components: {
    BaseHeader,
    BaseFooter,
  },
  data: () => ({
    isShowSideBar: false,
  }),

  methods: {
    handleSidebar(showSideBar) {
      this.isShowSideBar = showSideBar;
    },
  },
};
</script>

<style></style>
