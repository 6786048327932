import { ADD_PRODUCT, CHANGE_QUNTITY, REMOVE_ITEM, EMPTY_CART } from './types';

export const actions = {
  addItem({ commit, state }, payload) {
    commit(ADD_PRODUCT, payload);

    localStorage.setItem('cart', JSON.stringify(state));
  },
  changeQuantity({ commit, state }, payload) {
    commit(CHANGE_QUNTITY, payload);

    localStorage.setItem('cart', JSON.stringify(state));
  },
  removeItem({ commit, state }, payload) {
    commit(REMOVE_ITEM, payload);

    localStorage.setItem('cart', JSON.stringify(state));
  },
   getItem({ commit, state }, payload) {
    commit(ADD_PRODUCT, payload);

    localStorage.setItem('cart', JSON.stringify(state));
  },
  emptyCart({ commit, state }) {
    commit(EMPTY_CART);
    localStorage.setItem('cart', JSON.stringify(state));
  },
};
